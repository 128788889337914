import React from 'react';
import process1 from '../../../photo/process1.webp';
import process2 from '../../../photo/process2.webp';
import process3 from '../../../photo/process3.webp';
import process4 from '../../../photo/process4.webp';
import process5 from '../../../photo/process5.webp';
import process6 from '../../../photo/process6.webp';
import './feature.css';

const FeaturesSection = () => {
    const features = [
        {
            title: 'Receive by Mail',
            image: 'https://www.jsk247book.com/assets/img/icon/open-mail.png'
        },
        {
            title: 'Guaranteed ID\'s',
            image: 'https://www.jsk247book.com/assets/img/icon/guarantee-certificate.png'
        },
        {
            title: '100% Proofed',
            image: 'https://www.jsk247book.com/assets/img/icon/print-proof.png'
        },
        {
            title: 'Verified Odds',
            image: 'https://www.jsk247book.com/assets/img/icon/verified-account.png'
        },
        {
            title: 'Buyer Protection',
            image: 'https://www.jsk247book.com/assets/img/icon/protected-profile.png'
        },
        {
            title: 'Credits Based System',
            image: 'https://www.jsk247book.com/assets/img/icon/wallet.png'
        }
    ];

    return (
        <div className="container py-5">
            <h2 className="text-center mb-4">Benefits You Get!</h2>
            <p className="text-center mb-5">
                Features that users get<br />
                We provide Betting Sports ID hand in hand! So don't worry about learning from scratch, we've got your back. When it comes to having experience in this field, there's a lot of that we have.
            </p>
            <div className="row mt-5" >
                {/* Section 1: Features Cards */}
                <div className="col-lg-6">
                    <div className="row">
                        {features.map((feature, index) => (
                            <div className="col-6 col-md-6 mb-4" key={index}> {/* Use col-6 for mobile to display side by side */}
                                <div className="card crdfet h-100 text-center">
                                    <div className="divimg">
                                        <img
                                            src={feature.image}
                                            className="card-img-top crdfetimg"
                                            alt={`Feature ${index + 1}`}
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{feature.title}</h5>
                                        <a href="https://wa.link/zppwxy" className='aLink' ><button className="btn btn-primary animated-button">  Read More  </button> </a> 
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Section 2: Major Image */}
                <div className="col-lg-6 text-center">
                    <img
                        src='https://www.jsk247book.com/assets/img/feature-img.png'
                        alt="Major Features Overview"
                        className="img-fluid imgMaain"
                        style={{ maxHeight: '400px', objectFit: 'cover', marginTop: 'auto' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection;
