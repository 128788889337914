import React from 'react'
import './USP.css'
const USP = () => {
    return (
        <div>
            <div className="container-fluid USP">
                <div className="container contUsp">
                    <h4>
                        We are proud to say that we have the
                    </h4>
                    <h1>
                        Fastest Withdrawal System
                    </h1>
                    <h4>We are also known as</h4>
                    <h1>
                        The Most Trusted Book in India
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default USP
