import React from 'react';
import './Whyus.css';
import process1 from '../../../photo/process1.webp';
import process2 from '../../../photo/process2.webp';
import process3 from '../../../photo/process3.webp';
import process4 from '../../../photo/process4.webp';
import process5 from '../../../photo/process5.webp';
import process6 from '../../../photo/process6.webp';
const WhyChooseUs = () => {
    const benefits = [
        {
            title: '01. 100% TRUSTED SERVICE',
            description:
                'Working since 2015 with experienced and expertise, We are a company, not a dealer that makes us the most trusted for our players.',
            image: process1 // Assuming these images are in the public folder or accessible path
        },
        {
            title: '02. MOST POPULAR SPORTS',
            description:
                'You can bet on cricket matches in national leagues and international leagues and competitions. But that’s just for starters. You can also bet on tennis, football rugby, casino, and virtually any other sport.',
            image: process2
        },
        {
            title: '03. VIP PROGRAMS',
            description:
                'We know that many of our readers are high rollers, so we make sure our recommended betting sites all offer lucrative incentives to VIP casino players.',
            image: process3
        },
        {
            title: '04. 24/7 SUPPORT',
            description:
                'Customer support agents must be friendly and knowledgeable, so you can be confident that they can help you solve any problems that might arise. You can contact these agents via email, telephone, or live chat.',
            image: process4
        },
        {
            title: '05. INSTANT WITHDRAW & REFILL',
            description:
                'Withdrawal amount gets credited to your Bank A/c instantly with minimum to maximum amount of placing your withdrawal request.',
            image: process5
        },
        {
            title: '06. HIGH SECURITY',
            description:
                'Security is our highest priority. Your data and information cannot be shared at any cost. We will make sure you have a great experience with us.',
            image: process6
        }
    ];

    return (
        <div className="container py-5">
            <h2 className="text-center mb-5">Why Choose Us!</h2>
            <p className="text-center">
                Why Profit333 BOOK Bet Online<br />
                To know more about the advantages of choosing Profit333 BOOK Bet Online, join us in a game or two?<br />
                We promise you won't turn your back afterwards!
            </p>
            <div className="row">
                {benefits.map((benefit, index) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-9 mb-4" key={index}>
                        <div className="card cardben h-100 ">
                            <img
                                src={benefit.image}
                                className="card-img-top cardimg"
                                alt={`Process ${index + 1}`}
                                style={{ height: '200px', objectFit: 'cover' }} 
                            />
                            <div className="card-body">
                                <h5 className="card-title benefit-title">
                                    <span className="benefit-number">{benefit.title.split('.')[0]}</span>
                                    {benefit.title.slice(3)}
                                </h5>
                                <p className="card-text">{benefit.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhyChooseUs;
