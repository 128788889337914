import React from "react";
import {
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTypography,
} from "mdb-react-ui-kit";

const App = () => {
    const testimonials = [
        {
            name: "Maria Smantha",
            title: "Professional Bettor",
            quote: "I've been using Profit333 for a year now, and their speedy withdrawal services are unmatched! I can always count on getting my funds quickly and efficiently.",
            imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
            rating: 5,
        },
        {
            name: "Lisa Cudrow",
            title: "Sports Analyst",
            quote: "Profit333 has changed the way I bet! The user-friendly interface and instant withdrawals keep me coming back for more. Highly recommend!",
            imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp",
            rating: 4.5,
        },
        {
            name: "John Smith",
            title: "Casual Bettor",
            quote: "I've tried several betting sites, but none offer the same level of service as Profit333. Their fast withdrawal process is a game-changer for me.",
            imageSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp",
            rating: 4,
        },
    ];

    return (
        <MDBContainer className="py-5">
            <MDBRow className="d-flex justify-content-center">
                <MDBCol md="10" xl="8" className="text-center">
                    <h3 className="mb-4">What Our Users Say</h3>
                    <p className="mb-4 pb-2 mb-md-5 pb-md-0">
                        At Profit333, we prioritize our users' experiences. Here's what they have to say about our speedy withdrawal services and overall betting experience.
                    </p>
                </MDBCol>
            </MDBRow>
            <MDBRow className="text-center">
                {testimonials.map((testimonial, index) => (
                    <MDBCol key={index} md="4" className="mb-5 mb-md-0">
                        <div className="d-flex justify-content-center mb-4">
                            <img
                                src={testimonial.imageSrc}
                                className="rounded-circle shadow-1-strong"
                                width="150"
                                height="150"
                                alt={testimonial.name}
                            />
                        </div>
                        <h5 className="mb-3">{testimonial.name}</h5>
                        <h6 className="text-primary mb-3">{testimonial.title}</h6>
                        <p className="px-xl-3">
                            <MDBIcon fas icon="quote-left" className="pe-2" />
                            {testimonial.quote}
                        </p>
                        <MDBTypography listUnStyled className="d-flex justify-content-center mb-0">
                            {Array.from({ length: 5 }, (_, index) => (
                                <li key={index}>
                                    <MDBIcon
                                        fas
                                        icon={index < Math.floor(testimonial.rating) ? "star" : "star-half-alt"}
                                        size="sm"
                                        className="text-warning"
                                    />
                                </li>
                            ))}
                        </MDBTypography>
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBContainer>
    );
};

export default App;
