import React from 'react'
import './Home.css'
import Typewriter from 'typewriter-effect';
const Home = () => {
    return (
        <div>
            <div className="container home">
                <h1>WIN YOUR DREAM BY PLAY WITH US ON OUR INDIA'S MOST POPULER EXCH LIKE </h1>
                <h1 className='h1typ'>
                <Typewriter
                            options={{
                                strings: ['D247', 'TENEXC', 'LORDS', 'WORLD777', 'DAIMOND', 'TAJ777', ],
                                autoStart: true,
                                loop: true,
                                wrapperClassName: 'typewriter-h1',
                            }}
                        />
                </h1>
            </div>
        </div>
    )
}

export default Home
