import React from 'react';
import './Footer.css'
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; 2023 Beeting. All rights reserved.</p>
                <ul className="footer-links">
                    <li><a href="https://wa.link/zppwxy">About Us</a></li>
                    <li><a href="https://wa.link/zppwxy">Contact Us</a></li>
                    <li><a href="https://wa.link/zppwxy">Terms & Conditions</a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;