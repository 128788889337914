import React from 'react';
import './Bonus.css';
import wlcimg from  '../../../photo/welcome.png'
import dep from '../../../photo/deposite.png'

const Bonus = () => {
    return (
        <div className='container-fluid bonus'>
            <div className="container animated-bg">
                <div className="title">
                    <h1>Bonuses For You</h1>
                </div>
                <div className="row bonrow">
                    <div className="col-md-5">
                        <div className="card glass-card">
                            <img 
                                src={wlcimg} 
                                className="card-img-top" 
                                alt="Bonus Image" 
                            />
                            <div className="card-body">
                                <h2 className="card-title">Welcome Bonus</h2>
                                <h4 className='mt-4'>Claim Your <span className='per'>11%</span> Welcome Bonus Right Now</h4>
                                <ul className='ulwlc'>
                                    <li> Rs. <span className='per'>1000</span> becomes Rs. <span className='per'>1110</span></li>
                                    <li> Rs. <span className='per'>2000</span> becomes Rs. <span className='per'>2220</span></li>
                                    <li> Rs. <span className='per'>10000</span> becomes Rs. <span className='per'>11100</span></li>
                                    <li> Rs. <span className='per'>100000</span> becomes Rs. <span className='per'>111000</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card glass-card  c2">
                            <img 
                                src={dep} 
                                className="card-img-top" 
                                alt="Bonus Image" 
                            />
                            <div className="card-body">
                                <h2 className="card-title">Every Deposit Bonus</h2>
                                <h4 className='mt-4'>Get <span className='per'>5%</span> on Every Deposit</h4>
                                <ul className='ulwlc'>
                                    <li> Rs. <span className='per'>1000</span> becomes Rs. <span className='per'>1050</span></li>
                                    <li> Rs. <span className='per'>2000</span> becomes Rs. <span className='per'>2100</span></li>
                                    <li> Rs. <span className='per'>10000</span> becomes Rs. <span className='per'>10500</span></li>
                                    <li> Rs. <span className='per'>100000</span> becomes Rs. <span className='per'>105000</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bonus;
