import React from 'react';
import './AboutUs.css';
import photoau from '../../../photo/about2.webp';
import { AwesomeButton } from "react-awesome-button";
import { FaWhatsapp } from 'react-icons/fa';// import WhatsApp icon

const AboutUs = () => {
    return (
        <div className="container-fluid mb-4">
            <div className='container' >
                <div className="title">
                    <h1>About Us</h1>
                </div>
                <div className="row">
                    <div className="col-md-6 p-3 imgdiv1">
                        <h5>Know About Profit333 BOOK Bet Online</h5>
                        <h2>BIGGEST BOOK OF INDIA</h2>
                        <p>If you're a sports freak, then you've come to the right destination! Welcome to Profit333 BOOK Bet Online, where we provide you with the best and most fun sports betting experience. From beginners to experts, everyone can participate. Want to know the best feature about this experience?

                            We provide Betting Sports IDs hand in hand! So don't worry about learning from scratch, we've got your back. When it comes to experience in this field, we have plenty of it.

                            At Profit333 BOOK Bet Online, we offer multiple levels of agency and client IDs: Admin, Senior, Super Senior, Super Master, Master, Player ID, and more!

                        </p>
                        <div className="button">
                            <a href="https://wa.link/zppwxy" className="animated-button">
                                Whatsapp Now !
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 imgdiv ">
                        <img src={photoau} alt="" className='imgau' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;