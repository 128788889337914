import React from 'react';
import './App.css';
import Navbar from './modules/main/navbar/Navbar';
import Home from './modules/main/Home/Home';
import AboutUs from './modules/main/AboutUs/AboutUs';
import Bonus from './modules/main/bonus/Bonus';
import WhyChooseUs from './modules/main/whyus/Whyus';
import FeaturesSection from './modules/main/feature/feature';
import Testimonial from './modules/main/testimonial/Testimonial';
import USP from './modules/main/usp/USP';
import Footer from './modules/main/footer/Footer';
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <div className="starry-line-bg"></div>
      <div className="container-fluid">
        <div className="container what">
        <a href="https://wa.link/zppwxy" className='aLink'>
          <div className="demo" >
            <FaWhatsapp /> +91 9653261075
          </div>
          </a>
        </div>
      </div>
      <Navbar />
      <Home />
      <AboutUs />
      <Bonus />
      <WhyChooseUs />
      <USP />
      <FeaturesSection />
      <Testimonial />
      <Footer />

      {/* Floating Button */}
      <button className="floating-button">
        <a className="aLink" href='https://wa.link/zppwxy'>
          <FaWhatsapp />
        </a>
      </button>
      <button className=" IG ">
        <a href="https://wa.link/zppwxy" className='aLink' >
          <FaInstagram />
        </a>
      </button>
    </div>
  );
}

export default App;
